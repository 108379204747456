export const resultMessages: Record<
  string,
  { title: string; subtitle?: string; description: string[] }
> = {
  audit: {
    title: "AUDIT + Forenzní služby",
    description: [
      "Umíš skvěle <strong>identifikovat potenciální rizika</strong> a&nbsp;dokážeš dobře <strong>chápat klientovo podnikání.</strong> Neanalyzuješ jen čísla a&nbsp;informace, ale jdeš hlouběji do&nbsp;komplexních procesů a&nbsp;dokážeš přijít i&nbsp;na&nbsp;<strong>příčinu žraločího tornáda.</strong>",
      "V&nbsp;auditu skvěle propojíš své multioborové znalosti, ekonomický rozhled a&nbsp;analytické myšlení.",
      'Až&nbsp;odhalíš, že tvým posláním je audit, volná místa hledej <strong><a href="https://cestasey.cz/volne-pozice">na&nbsp;našem webu</a>.</strong>',
    ],
  },
  strategy: {
    title: "STRATEGIE a&nbsp;TRANSAKCE",
    description: [
      "Malou násobilku jsi ovládal/a dřív, než jsi chodil/a na&nbsp;nočník a&nbsp;strategické hry už s&nbsp;tebou všichni odmítají hrát. Možná ani nevíš, kde se to v&nbsp;tobě bere, ale vždycky prostě víš, co dělat. Tak proč si za to nenechat zaplatit?",
      "<strong>Budeš radit klientům při nákupech, prodejích či fúzích firem.</strong> Na kapitálových trzích budeš jako doma. Uplatníš u&nbsp;nás <strong>precizní analýzu a interpretaci informací.</strong> A&nbsp;hodit se ti budou efektivní komunikace a&nbsp;schopnost budování vztahů s&nbsp;klienty.",
      'Přihlášku do&nbsp;životní školy EY, kde tě naučíme všem prodejním kouzlům, najdeš <strong><a href="https://cestasey.cz/volne-pozice">na&nbsp;našem webu</a>.</strong>',
    ],
  },
  consulting: {
    title: "CONSULTING",
    subtitle: "IT, developeři, technologie, business",
    description: [
      "Dokážeš své <strong>nápady transformovat ve funkční řešení</strong> a <strong>umíš ostatním vždy poradit</strong>, často i&nbsp;když se nikdo neptal? V&nbsp;EY tohle oceníme.",
      "Pomáhej s&nbsp;námi firmám vylepšovat IT systémy a&nbsp;orientovat se v&nbsp;datech, nebo jen promítni své nápady do&nbsp;oblastí energetiky, bankovnictví nebo automotive průmyslu. Pojď konečně uplatnit svůj parádní rozhled.",
      'Zkonzultuj to sám/sama se sebou… A&nbsp;až ti dojde, že je to match, volnou pozici najdeš <strong><a href="https://cestasey.cz/volne-pozice">na&nbsp;našem webu</a>.</strong>',
    ],
  },
  taxes: {
    title: "DANĚ",
    description: [
      "Komplikovaný <strong>svět daní pro tebe znamená oázu radosti</strong> a&nbsp;jsi v&nbsp;něm jako doma. Jenže naši klienti to tak jako ty nemají, takže jim můžeš pomoct. S&nbsp;čím? Třeba s&nbsp;každodenními provozními otázkami kolem zdanění zaměstnanců, daně z&nbsp;příjmů právnických osob, DPH a&nbsp;vedení účetnictví a&nbsp;spoustou dalšího.",
      "Kombinujeme znalosti a zkušenosti našich daňařů, takže budeš moct využít <strong>přesně ty skills, které máš nejvypilovanější a&nbsp;baví tě nejvíc.</strong> Jo, a&nbsp;taky tě můžeme poslat třeba na&nbsp;<strong>půlroční pracovní challenge do&nbsp;New Yorku.</strong>",
      'Až budeš mít spočítáno, že chceš být daňařem/daňařkou, volné pozice hledej <strong><a href="https://cestasey.cz/volne-pozice">na&nbsp;našem webu</a>.</strong>',
    ],
  },
};
